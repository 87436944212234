.compact-table {
  letter-spacing: -1px;
  td {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:hover {
    overflow: visible;
    white-space: normal;
    max-width: unset !important;
  }
}
