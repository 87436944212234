.query-table-container {
  display: grid;
  grid-template-rows: auto 1fr auto;

  .query-header-controls {
    grid-row: 1;
  }

  .query-table-header {
    position: sticky;
    z-index: 2;
    border: 1px solid #eee;
  }

  .query-table-header-cell {
    display: flex;
    cursor: pointer;
    align-items: center;
    max-width: 200px;
  }

  ul {
    list-style: none;
    margin-left: 0px;
  }

  ul,
  ol,
  li,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  td {
    // max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }

  .header-text {
    min-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    font-size: 1rem;
    display: flex;
    flex: 1 1 auto;
  }

  .header-text.active {
    color: #af0200;
  }

  .query-table {
    grid-row: 2;
    height: calc(100vh - 200px);
    overflow: auto;
    width: 100%;
    margin-bottom: 0;
    background: #f5f5f5;
    border: 1px solid #eee;
    position: relative;

    table {
      table-layout: fixed;
      width: 100%;
      background: white;
      margin-bottom: 0;

      &.loading {
        opacity: 0.5;
      }
    }

    tr.even {
      background: #fff;
    }

    tr.odd {
      background: #f9f9f9;
    }
  }

  .query-table.dynamic-columns {
    table {
      table-layout: auto;
    }
  }

  // TODO: refactor style sheet assing properties to dynamic columns class so important declaration is not needed
  .query-table.static-columns {
    overflow: unset !important;

    .query-table-container {
      overflow: unset !important;
    }

    table {
      table-layout: auto !important;
    }

    .query-table-header-cell {
      width: unset;
    }

    .query-table-header-cell-container {
      min-width: unset;
      position: unset !important;
    }

    .header-text {
      font-size: 0.9em !important;
      min-width: 35px !important;
    }
  }

  .query-footer {
    grid-row: 3;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    background: #eee;
    border: 1px solid #eee;
    padding: 0.5rem 10px;

    .page-select {
      display: grid;
      grid-template-columns: auto auto auto auto;
      flex: 1 1 auto;
      grid-gap: 10px;
      align-items: center;
      justify-content: center;
      justify-self: center;
      justify-items: center;

      select {
        width: 45px;
        margin: 0;
      }

      .next-page,
      .previous-page {
        color: #4281a6;
        cursor: pointer;
        min-width: 40px;
      }
    }
  }

  .per-page-options-container {
    display: flex;
    align-items: center;

    label {
      color: #4281a6;
    }

    input,
    select {
      margin: 0px 10px;
      width: 60px;
    }

    .per-page-options-mode {
      cursor: pointer;
      font-size: 1.5em;

      &:hover {
        filter: brightness(180%);
        transform: scale(1.1);
      }
    }
  }

  .total-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    color: #4281a6;

    .count-label {
      padding-right: 0.5rem;
    }

    .count-value {
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 680px) {

  .query-table-container,
  .query-table {
    height: 100% !important;
    overflow: unset !important;
  }
}

.query-table-container {
  table {
    table-layout: fixed;
    width: 100%;
    background: white;
    margin-bottom: 0;
    border-collapse: collapse;
    border-spacing: 0;
    border-collapse: collapse;
    border-spacing: 0;
    text-indent: initial;
  }

  thead {
    background: #f5f5f5;
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  table tr.even,
  table tr.alt,
  table tr:nth-of-type(even) {
    background: #f9f9f9;
  }
}
