.dropdown {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
}

*,
:after,
:before {
  box-sizing: inherit;
}

.dropdown-content {
  z-index: 3;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 0px 15px 2px rgba(15, 15, 15, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  margin-top: 0px;
  position: absolute;
  max-height: 300px;
  width: 200px;
  overflow-y: auto;
  z-index: 3;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 0px 15px 2px rgba(15, 15, 15, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  position: absolute;
  max-height: 300px;
  width: 200px;
  overflow-y: auto;
  margin: 20px;

  display: block;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown-command {
  background: linear-gradient(#efefef, #eaeaea);
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 0 0 1px;
  box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex: 1;
  font-feature-settings: "c2sc", "smcp";
  font-variant: small-caps;
  justify-content: center;
  padding: 10px 5px;
  padding: 10px;
  text-transform: lowercase;

  &:hover {
    background: #ddd;
  }
}

.dropdown-item {
  flex: 1;
  align-items: center;
  background: linear-gradient(#efefef, #eaeaea);
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 0 0 1px;
  cursor: pointer;
  display: grid;
  font-weight: 400;
  grid-gap: 10px;
  grid-template-columns: auto 1fr;
  line-height: 1.5;
  padding: 10px;
  text-align: center;
  user-select: none;
  padding-left: 20px !important;

  &:hover {
    background: #ddd;
  }
}

.dropdown {
  display: inline-block;
  position: relative;
  $base-width: 200px;
  $base-height: 300px;
  .dropdown-content {
    z-index: 3;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 0px 15px 2px rgba(15, 15, 15, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    margin-top: 0px;
    position: absolute;
    &.right {
      left: 0;
    }
    &.left {
      right: 0;
    }
    &.middle {
      left: -100px;
    }
    max-height: $base-height;
    width: $base-width;
    overflow-y: auto;
  }
}
// .dropdown-content {
//   .dropdown-list {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     .dropdown-command {
//       cursor: pointer;
//       display: flex;
//       padding: 10px;
//       justify-content: center;
//       box-shadow: 0 4px 5px -1px rgba(0, 0, 0, 0.1);
//       text-transform: lowercase;
//       font-feature-settings: "c2sc";
//       font-variant: small-caps;
//       &:hover {
//         background: #eee;
//       }
//     }
//     .dropdown-item {
//       user-select: none;
//       cursor: pointer;
//       display: grid;
//       grid-template-columns: auto 1fr;
//       grid-gap: 10px;
//       align-items: center;
//       padding: 10px;
//       line-height: 1.5;
//       font-weight: 400;
//       &:hover {
//         background: #eee;
//       }
//       input[type="checkbox"] {
//         margin: 0;
//       }
//     }
//     .dropdown-item.disabled {
//       pointer-events: none;
//       cursor: default;
//       color: #ccc;
//     }
//   }
// }
