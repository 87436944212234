.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999;
  box-sizing: border-box;
}

.modal.modal-fade {
  animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.modal > .modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal > .modal-close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #5e5e5e;
  cursor: pointer;
  font-size: 1.25em;
  padding: 7px;
  background: rgba(255, 255, 255, 0.749);
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
}

.modal > .modal-close:hover {
  background: rgba(255, 255, 255, 0.989);
}

.modal > .modal-body {
  z-index: 2;
  position: relative;
  margin: 0 20px;
  background-color: #303030;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  max-height: 100%;
  padding: 15px 20px;
  color: #c3c0c0;
}
@keyframes fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.modal-body {
  overflow: scroll;
}

.modal-body::-webkit-scrollbar {
  width: 18px;
}

.modal-body::-webkit-scrollbar-track {
  background: #fff;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #24abfb;
}

modal-body::-webkit-scrollbar-thumb:horizontal {
  background: #24abfb;
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.modal-body::-webkit-scrollbar-button {
  display: none;
}

.modal-body::-webkit-scrollbar-thumb:horizontal {
  background: #24abfb;
  width: 14px;
  border-radius: 10px;
}
